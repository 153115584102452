import axios from "axios";

const httpClient = axios.create({
    // baseURL: 'https://localhost:7148/api/',
    baseURL: 'https://bbapi.vevasoftprojects.com/api/',
});
httpClient.interceptors.request.use(function(config) {

    if (localStorage.auth_token) {
        config.headers.Authorization = `Bearer ${localStorage.auth_token}`;
    }

    return config;
});

httpClient.interceptors.response.use(function(config){
    return config;
},function(err){
    if (err.response.status === 401){
        localStorage.removeItem("auth_token");
        window.location.reload();
    }else{
       return Promise.reject(err);
    }

})


export default httpClient;
